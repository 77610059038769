<template>
  <fragment>
    <div class="d-flex flex-column flex-root">
      <KTHeaderMobile></KTHeaderMobile>

      <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

      <div class="d-flex flex-row flex-column-fluid page">
        <KTAside></KTAside>

        <div
          class="d-flex flex-column flex-row-fluid wrapper"
          :class="[
            showSubMenu == null && !$route.meta.showHeader && `header-space`,
          ]"
          id="kt_wrapper"
        >
          <KTHeader
            v-if="showSubMenu != null || $route.meta.showHeader"
            :showSubMenu="showSubMenu"
            :subMenuItem="subMenuItem"
            @onSelectStore="openModalStore"
          ></KTHeader>
          <div
            class="content d-flex flex-column flex-column-fluid"
            id="kt_content"
          >
            <KTSubheader
              v-if="
                subheaderDisplay &&
                displaySubheaderOnDashboard &&
                showSubMenu != null
              "
              v-bind:breadcrumbs="breadcrumbs"
              v-bind:title="pageTitle"
            />
            <div class="d-flex flex-column-fluid">
              <div
                :class="{
                  'container-fluid': contentFluid,
                  container: !contentFluid,
                }"
              >
                <transition name="fade-in-up">
                  <router-view />
                </transition>
              </div>
            </div>
          </div>
          <KTFooter></KTFooter>
          <KTScrollTop></KTScrollTop>
        </div>
      </div>
    </div>

    <b-modal
      v-model="modal.show"
      title="Pilih Store"
      :no-close-on-backdrop="true"
      :hide-header-close="true"
      :hide-footer="true"
    >
      <b-list-group>
        <b-list-group-item
          href="javascript:;"
          :active="item.id == store?.id"
          v-for="(item, i) in stores"
          v-bind:key="i"
          @click="handleSelectedStore(item)"
        >
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">{{ item.storeName }}</h5>
            <small>{{ hoursAgo(item.lastTransaction) }}</small>
          </div>

          <p class="mb-1">
            {{ [item.address, item.city, item.provinsi].join(", ") }}
          </p>

          <small>{{ item.services }}</small>
        </b-list-group-item>
      </b-list-group>
    </b-modal>
  </fragment>
</template>

<script>
import { mapGetters } from "vuex";
import { subMenu } from "@/core/datasource/subMenu";
import HtmlClass from "@/core/services/htmlclass.service";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTSubheader from "@/view/layout/subheader/Subheader.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile";
import Loader from "@/view/layout/content/Loader";
import KTHeader from "@/view/layout/header/Header";
import KTFooter from "@/view/layout/footer/Footer";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import { hoursAgo } from "@/core/utils";
import { SET_STORE } from "@/core/services/store/personalize.module.js";
// import {
//   // ADD_BODY_CLASSNAME,
//   // REMOVE_BODY_CLASSNAME,
// } from "@/core/services/store/htmlclass.module.js";

export default {
  name: "Layout",
  components: {
    KTAside,
    KTSubheader,
    KTHeaderMobile,
    Loader,
    KTHeader,
    KTFooter,
    KTScrollTop,
  },
  data: () => ({
    modal: {
      show: false,
    },
    stores: [],
  }),
  beforeMount() {
    // this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    HtmlClass.init(this.layoutConfig());
  },
  created() {
    const self = this;

    const needStore = ["penjualan", "club-member"];
    if (needStore.includes(self.$route.name)) {
      if (typeof self.store == undefined || self.store?.id == null) {
        self.openModalStore();
      }
    }
    /* setTimeout(() => {
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000); */
  },
  computed: {
    ...mapGetters(["breadcrumbs", "pageTitle", "layoutConfig", "store"]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },

    /**
     * Set the subheader display on dashboard page
     * @returns {boolean}
     */
    displaySubheaderOnDashboard() {
      return this.$route.name !== "dashboard";
    },

    showSubMenu() {
      const self = this;
      let find = subMenu.find((x) => self.$route.name.includes(x.routeName));
      /* let find = null;
      subMenu.forEach((item) => {
        if (!item.children && item.routeName == self.$route.name) find = item;

        if (find == null && item.children) {
          item.children.forEach((child) => {
            if (child.routeName == self.$route.name) {
              find = child;
            }
          });
        }
      }); */

      return find;
    },

    subMenuItem() {
      const self = this;
      let parent = subMenu.find((x) => self.$route.name.includes(x.routeName));
      /* let parent = null;
      subMenu.forEach((item) => {
        if (!item.children && item.routeName == self.$route.name) parent = item;

        if (parent == null && item.children) {
          item.children.forEach((child) => {
            if (child.routeName == self.$route.name) {
              parent = child;
            }
          });
        }
      }); */

      if (parent != null) {
        let find = subMenu.filter((x) => x.parentId == parent.parentId);
        return find;
      }

      return null;
    },
  },
  methods: {
    hoursAgo,
    openModalStore() {
      const self = this;

      self.stores = [];
      self.$store
        .dispatch("apis/get", {
          url: `/store`,
          params: {
            pageNumber: 1,
            pageSize: 0,
            actived: true,
          },
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });
          } else {
            self.stores = response.data.data;
            self.modal.show = true;
          }
        });
    },
    handleSelectedStore(store) {
      const self = this;
      self.$store.dispatch(SET_STORE, store);
      self.modal.show = false;

      // self.$router.go();
    },
  },
};
</script>
