var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"menu-nav"},_vm._l((_vm.subMenu),function(item,i){return _c('fragment',{key:i},[_c('router-link',{attrs:{"to":{
        name: item.routeName,
      }},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate }){return [_c('li',{staticClass:"menu-item",class:[
          (item.routeName == _vm.$route.name ||
            item.routeNames?.includes(_vm.$route.name)) &&
            'menu-item-active',
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(item.label))])])])]}}],null,true)})],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }