var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fragment',[_c('div',{staticClass:"d-flex flex-column flex-root"},[_c('KTHeaderMobile'),(_vm.loaderEnabled)?_c('Loader',{attrs:{"logo":_vm.loaderLogo}}):_vm._e(),_c('div',{staticClass:"d-flex flex-row flex-column-fluid page"},[_c('KTAside'),_c('div',{staticClass:"d-flex flex-column flex-row-fluid wrapper",class:[
          _vm.showSubMenu == null && !_vm.$route.meta.showHeader && `header-space`,
        ],attrs:{"id":"kt_wrapper"}},[(_vm.showSubMenu != null || _vm.$route.meta.showHeader)?_c('KTHeader',{attrs:{"showSubMenu":_vm.showSubMenu,"subMenuItem":_vm.subMenuItem},on:{"onSelectStore":_vm.openModalStore}}):_vm._e(),_c('div',{staticClass:"content d-flex flex-column flex-column-fluid",attrs:{"id":"kt_content"}},[(
              _vm.subheaderDisplay &&
              _vm.displaySubheaderOnDashboard &&
              _vm.showSubMenu != null
            )?_c('KTSubheader',{attrs:{"breadcrumbs":_vm.breadcrumbs,"title":_vm.pageTitle}}):_vm._e(),_c('div',{staticClass:"d-flex flex-column-fluid"},[_c('div',{class:{
                'container-fluid': _vm.contentFluid,
                container: !_vm.contentFluid,
              }},[_c('transition',{attrs:{"name":"fade-in-up"}},[_c('router-view')],1)],1)])],1),_c('KTFooter'),_c('KTScrollTop')],1)],1)],1),_c('b-modal',{attrs:{"title":"Pilih Store","no-close-on-backdrop":true,"hide-header-close":true,"hide-footer":true},model:{value:(_vm.modal.show),callback:function ($$v) {_vm.$set(_vm.modal, "show", $$v)},expression:"modal.show"}},[_c('b-list-group',_vm._l((_vm.stores),function(item,i){return _c('b-list-group-item',{key:i,attrs:{"href":"javascript:;","active":item.id == _vm.store?.id},on:{"click":function($event){return _vm.handleSelectedStore(item)}}},[_c('div',{staticClass:"d-flex w-100 justify-content-between"},[_c('h5',{staticClass:"mb-1"},[_vm._v(_vm._s(item.storeName))]),_c('small',[_vm._v(_vm._s(_vm.hoursAgo(item.lastTransaction)))])]),_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s([item.address, item.city, item.provinsi].join(", "))+" ")]),_c('small',[_vm._v(_vm._s(item.services))])])}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }