export const subMenu = [
  {
    parentId: "transaction",
    routeName: "pos",
    label: "POS System",
    chooseStore: true,
  },
  {
    parentId: "transaction",
    routeName: "pengeluaran",
    label: "Pengeluaran",
    chooseStore: true,
    routeNames: ["pengeluaranCreate", "pengeluaranUpdate"],
  },
  {
    parentId: "transaction",
    routeName: "outstanding",
    label: "Outstanding",
    chooseStore: true,
    routeNames: ["outstandingUpdate"],
  },
  {
    parentId: "clubMember",
    routeName: "checkIn",
    label: "Club Member System",
    chooseStore: true,
  },
  {
    parentId: "clubMember",
    routeName: "member",
    label: "Member",
    chooseStore: true,
    routeNames: ["memberCreate", "memberUpdate"],
  },
  // {
  //   parentId: "storePoint",
  //   routeName: "store",
  //   label: "Store",
  //   chooseStore: false,
  // },
  // {
  //   parentId: "storePoint",
  //   routeName: "storeProduct",
  //   label: "Produk",
  //   chooseStore: false,
  //   routeNames: ["storeProductCreate", "storeProductUpdate"],
  // },
  // {
  //   parentId: "storePoint",
  //   routeName: "storePaymentMethod",
  //   label: "Metode Pembayaran",
  //   chooseStore: false,
  //   routeNames: ["storePaymentMethodCreate", "storePaymentMethodUpdate"],
  // },
];
