<template>
  <div class="aside aside-left d-flex flex-column" id="kt_aside" ref="kt_aside">
    <div
      class="aside-brand d-flex flex-column align-items-center flex-column-auto pt-5 pt-lg-18 pb-10"
    >
      <div
        class="btn p-0 symbol symbol-60 symbol-light-primary"
        id="kt_quick_user_toggle"
      >
        <div class="symbol-label">
          <inline-svg
            v-if="
              store == null || store.logo == null || store.logo.label == null
            "
            src="media/svg/avatars/001-boy.svg"
            class="h-75 align-self-end"
          />
          <img v-else :src="mediaUrl(store.logo.label)" class="h-75" />
        </div>
      </div>
    </div>
    <div
      class="aside-nav d-flex flex-column align-items-center flex-column-fluid pb-10"
    >
      <ul class="nav flex-column">
        <li class="nav-item mb-2" v-b-tooltip.hover.right="'Transaksi'">
          <router-link
            :to="{
              name: `pos`,
            }"
            class="nav-link btn btn-icon btn-hover-text-primary btn-lg"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <a :href="href" @click="navigate">
              <span
                class="svg-icon svg-icon-xxl"
                :class="[
                  isActive && 'svg-icon-primary',
                  isExactActive && 'svg-icon-primary',
                  hasActiveChildren('/transaction') && 'svg-icon-primary',
                ]"
              >
                <inline-svg src="media/svg/icons/Devices/Printer.svg" />
              </span>
            </a>
          </router-link>
        </li>
        <li
          v-if="store?.clubMemberSystem"
          class="nav-item mb-2"
          v-b-tooltip.hover.right="'Membership'"
        >
          <router-link
            :to="{
              name: `checkIn`,
            }"
            class="nav-link btn btn-icon btn-hover-text-primary btn-lg"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <a :href="href" @click="navigate">
              <span
                class="svg-icon svg-icon-xxl"
                :class="[
                  isActive && 'svg-icon-primary',
                  isExactActive && 'svg-icon-primary',
                  hasActiveChildren('/club-member') && 'svg-icon-primary',
                ]"
              >
                <inline-svg
                  src="media/svg/icons/Communication/Adress-book2.svg"
                />
              </span>
            </a>
          </router-link>
        </li>
        <!-- <li
          v-if="user.businessOwner"
          class="nav-item mb-2"
          v-b-tooltip.hover.right="'Dashboard'"
        >
          <router-link
            :to="{
              name: `dashboard`,
            }"
            class="nav-link btn btn-icon btn-hover-text-primary btn-lg"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <a :href="href" @click="navigate">
              <span
                class="svg-icon svg-icon-xxl"
                :class="[
                  isActive && 'svg-icon-primary',
                  isExactActive && 'svg-icon-primary',
                ]"
              >
                <inline-svg src="media/svg/icons/Layout/Layout-4-blocks.svg" />
              </span>
            </a>
          </router-link>
        </li> -->
        <!-- <li
          v-if="user.businessOwner"
          class="nav-item mb-2"
          v-b-tooltip.hover.right="'Produk'"
        >
          <router-link
            :to="{
              name: `product`,
            }"
            class="nav-link btn btn-icon btn-hover-text-primary btn-lg"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <a :href="href" @click="navigate">
              <span
                class="svg-icon svg-icon-xxl"
                :class="[
                  isActive && 'svg-icon-primary',
                  isExactActive && 'svg-icon-primary',
                ]"
              >
                <inline-svg src="media/svg/icons/Shopping/Cart2.svg" />
              </span>
            </a>
          </router-link>
        </li> -->
        <!-- <li
          v-if="user.businessOwner"
          class="nav-item mb-2"
          v-b-tooltip.hover.right="'Store Point'"
        >
          <router-link
            :to="{
              name: `store`,
            }"
            class="nav-link btn btn-icon btn-hover-text-primary btn-lg"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <a :href="href" @click="navigate">
              <span
                class="svg-icon svg-icon-xxl"
                :class="[
                  isActive && 'svg-icon-primary',
                  isExactActive && 'svg-icon-primary',
                  hasActiveChildren('/store') && 'svg-icon-primary',
                ]"
              >
                <inline-svg src="media/svg/icons/Home/Home.svg" />
              </span>
            </a>
          </router-link>
        </li> -->
        <!-- <li
          v-if="user.businessOwner"
          class="nav-item mb-2"
          v-b-tooltip.hover.right="'Manajemen User'"
        >
          <router-link
            :to="{
              name: `userManagement`,
            }"
            class="nav-link btn btn-icon btn-hover-text-primary btn-lg"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <a :href="href" @click="navigate">
              <span
                class="svg-icon svg-icon-xxl"
                :class="[
                  isActive && 'svg-icon-primary',
                  isExactActive && 'svg-icon-primary',
                ]"
              >
                <inline-svg src="media/svg/icons/Communication/Group.svg" />
              </span>
            </a>
          </router-link>
        </li> -->
        <li class="nav-item mb-2" v-b-tooltip.hover.right="'Laporan'">
          <a
            href="#"
            class="nav-link btn btn-icon btn-hover-text-primary btn-lg"
            id="kt_laporan_toggle"
          >
            <span
              class="svg-icon svg-icon-xxl"
              :class="[hasActiveChildren('/laporan') && 'svg-icon-primary']"
            >
              <inline-svg src="media/svg/icons/Files/File-plus.svg" />
            </span>
          </a>
        </li>
      </ul>
    </div>
    <div
      class="aside-footer d-flex flex-column align-items-center flex-column-auto py-8"
    >
      <!-- <KTQuickNotifications></KTQuickNotifications>
      <KTQuickActions></KTQuickActions> -->
      <KTQuickLogout></KTQuickLogout>
    </div>

    <div id="kt_laporan" ref="kt_laporan" class="offcanvas offcanvas-left p-10">
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-10"
        kt-hidden-height="56"
        style=""
      >
        <h3 class="font-weight-bold m-0">Laporan</h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_laporan_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <div
        class="offcanvas-content pr-5 mr-n5 scroll ps"
        style="height: 804px; overflow: hidden"
      >
        <div class="row gutter-b">
          <div class="col-6">
            <router-link
              :to="{
                name: `laporanTransaksi`,
              }"
              class="btn btn-block text-center py-10 px-5"
              :class="[
                (`laporanTransaksi` == $route.name ||
                  [`laporanTransaksi`].includes($route.name)) &&
                  'btn-primary',
                (`laporanTransaksi` != $route.name ||
                  ![`laporanTransaksi`].includes($route.name)) &&
                  'btn-light btn-hover-primary text-dark-50',
              ]"
            >
              <span
                class="svg-icon svg-icon-3x m-0"
                :class="[
                  (`laporanTransaksi` != $route.name ||
                    ![`laporanTransaksi`].includes($route.name)) &&
                    'svg-icon-primary',
                ]"
              >
                <inline-svg src="media/svg/icons/Design/Interselect.svg" />
              </span>
              <span class="d-block font-weight-bold font-size-h6 mt-2">
                Transaksi
              </span>
            </router-link>
          </div>
          <div class="col-6">
            <router-link
              :to="{
                name: `laporanPengeluaran`,
              }"
              class="btn btn-block text-center py-10 px-5"
              :class="[
                (`laporanPengeluaran` == $route.name ||
                  [`laporanPengeluaran`].includes($route.name)) &&
                  'btn-primary',
                (`laporanPengeluaran` != $route.name ||
                  ![`laporanPengeluaran`].includes($route.name)) &&
                  'btn-light btn-hover-primary text-dark-50',
              ]"
            >
              <span
                class="svg-icon svg-icon-3x m-0"
                :class="[
                  (`laporanPengeluaran` != $route.name ||
                    ![`laporanPengeluaran`].includes($route.name)) &&
                    'svg-icon-primary',
                ]"
              >
                <inline-svg
                  src="media/svg/icons/Layout/Layout-top-panel-5.svg"
                />
              </span>
              <span class="d-block font-weight-bold font-size-h6 mt-2">
                Pengeluaran
              </span>
            </router-link>
          </div>
          <!-- <div class="col-6">
            <router-link
              :to="{
                name: `laporanPenjualan`,
              }"
              class="btn btn-block text-center py-10 px-5"
              :class="[
                (`laporanPenjualan` == $route.name ||
                  [`laporanPenjualan`].includes($route.name)) &&
                  'btn-primary',
                (`laporanPenjualan` != $route.name ||
                  ![`laporanPenjualan`].includes($route.name)) &&
                  'btn-light btn-hover-primary text-dark-50',
              ]"
            >
              <span
                class="svg-icon svg-icon-3x m-0"
                :class="[
                  (`laporanPenjualan` != $route.name ||
                    ![`laporanPenjualan`].includes($route.name)) &&
                    'svg-icon-primary',
                ]"
              >
                <inline-svg src="media/svg/icons/Shopping/Cart2.svg" />
              </span>
              <span class="d-block font-weight-bold font-size-h6 mt-2">
                Penjualan
              </span>
            </router-link>
          </div> -->
        </div>
        <div class="row gutter-b">
          <div class="col-6">
            <router-link
              :to="{
                name: `laporanShift`,
              }"
              class="btn btn-block text-center py-10 px-5"
              :class="[
                (`laporanShift` == $route.name ||
                  [`laporanShift`].includes($route.name)) &&
                  'btn-primary',
                (`laporanShift` != $route.name ||
                  ![`laporanShift`].includes($route.name)) &&
                  'btn-light btn-hover-primary text-dark-50',
              ]"
            >
              <span
                class="svg-icon svg-icon-3x m-0"
                :class="[
                  (`laporanShift` != $route.name ||
                    ![`laporanShift`].includes($route.name)) &&
                    'svg-icon-primary',
                ]"
              >
                <inline-svg src="media/svg/icons/Navigation/Exchange.svg" />
              </span>
              <span class="d-block font-weight-bold font-size-h6 mt-2">
                Shift
              </span>
            </router-link>
          </div>
          <!-- <div class="col-6">
            <router-link
              :to="{
                name: `laporanPenjualan`,
              }"
              class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5"
            >
              <span class="svg-icon svg-icon-3x svg-icon-primary m-0">
                <inline-svg src="media/svg/icons/Shopping/Chart-bar1.svg" />
              </span>
              <span class="d-block font-weight-bold font-size-h6 mt-2">
                Penjualan
              </span>
            </router-link>
          </div> -->
          <!-- <div class="col-6">
            <router-link
              :to="{
                name: `laporanPenjualan`,
              }"
              class="btn btn-block btn-light btn-hover-primary text-dark-50 text-center py-10 px-5"
            >
              <span class="svg-icon svg-icon-3x svg-icon-primary m-0">
                <inline-svg src="media/svg/icons/Shopping/Cart2.svg" />
              </span>
              <span class="d-block font-weight-bold font-size-h6 mt-2">
                Penjualan Produk
              </span>
            </router-link>
          </div> -->
        </div>
        <div v-if="store?.clubMemberSystem" class="row gutter-b">
          <div class="col-6">
            <router-link
              :to="{
                name: `laporanMembership`,
              }"
              class="btn btn-block text-center py-10 px-5"
              :class="[
                (`laporanMembership` == $route.name ||
                  [`laporanMembership`].includes($route.name)) &&
                  'btn-primary',
                (`laporanMembership` != $route.name ||
                  ![`laporanMembership`].includes($route.name)) &&
                  'btn-light btn-hover-primary text-dark-50',
              ]"
            >
              <span
                class="svg-icon svg-icon-3x m-0"
                :class="[
                  (`laporanMembership` != $route.name ||
                    ![`laporanMembership`].includes($route.name)) &&
                    'svg-icon-primary',
                ]"
              >
                <inline-svg
                  src="media/svg/icons/Communication/Address-card.svg"
                />
              </span>
              <span class="d-block font-weight-bold font-size-h6 mt-2">
                Membership
              </span>
            </router-link>
          </div>
          <div class="col-6">
            <router-link
              :to="{
                name: `laporanCheckIn`,
              }"
              class="btn btn-block text-center py-10 px-5"
              :class="[
                (`laporanCheckIn` == $route.name ||
                  [`laporanCheckIn`].includes($route.name)) &&
                  'btn-primary',
                (`laporanCheckIn` != $route.name ||
                  ![`laporanCheckIn`].includes($route.name)) &&
                  'btn-light btn-hover-primary text-dark-50',
              ]"
            >
              <span
                class="svg-icon svg-icon-3x m-0"
                :class="[
                  (`laporanCheckIn` != $route.name ||
                    ![`laporanCheckIn`].includes($route.name)) &&
                    'svg-icon-primary',
                ]"
              >
                <inline-svg src="media/svg/icons/Media/Repeat.svg" />
              </span>
              <span class="d-block font-weight-bold font-size-h6 mt-2">
                Check In
              </span>
            </router-link>
          </div>
        </div>
        <div v-if="store?.clubMemberSystem" class="row gutter-b">
          <div class="col-6">
            <router-link
              :to="{
                name: `laporanMembercardStock`,
              }"
              class="btn btn-block text-center py-10 px-5"
              :class="[
                (`laporanMembercardStock` == $route.name ||
                  [`laporanMembercardStock`].includes($route.name)) &&
                  'btn-primary',
                (`laporanMembercardStock` != $route.name ||
                  ![`laporanMembercardStock`].includes($route.name)) &&
                  'btn-light btn-hover-primary text-dark-50',
              ]"
            >
              <span
                class="svg-icon svg-icon-3x m-0"
                :class="[
                  (`laporanMembercardStock` != $route.name ||
                    ![`laporanMembercardStock`].includes($route.name)) &&
                    'svg-icon-primary',
                ]"
              >
                <inline-svg src="media/svg/icons/Shopping/Credit-card.svg" />
              </span>
              <span class="d-block font-weight-bold font-size-h6 mt-2">
                Stok Kartu Member
              </span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import KTLayoutQuickLaporan from "@/assets/js/layout/extended/quick-laporan.js";

import { mapGetters } from "vuex";
import KTLayoutAside from "@/assets/js/layout/base/aside.js";
// import KTQuickNotifications from "@/view/layout/extras/offcanvas/QuickNotifications.vue";
// import KTQuickActions from "@/view/layout/extras/offcanvas/QuickActions.vue";
// import KTQuickPanel from "@/view/layout/extras/offcanvas/QuickPanel.vue";
import KTQuickLogout from "@/view/layout/extras/offcanvas/QuickLogout.vue";
import { mediaUrl } from "@/core/utils";

export default {
  name: "Aside",
  components: {
    // KTQuickNotifications,
    // KTQuickActions,
    // KTQuickPanel,
    KTQuickLogout,
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters(["store"]),
  },
  mounted() {
    KTLayoutQuickLaporan.init(this.$refs["kt_laporan"]);

    this.$nextTick(() => {
      KTLayoutAside.init(this.$refs["kt_aside"]);
    });
  },
  methods: {
    mediaUrl,
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>
