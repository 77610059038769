<template>
  <ul class="menu-nav">
    <fragment v-for="(item, i) in subMenu" v-bind:key="i">
      <router-link
        :to="{
          name: item.routeName,
        }"
        v-slot="{ href, navigate }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            (item.routeName == $route.name ||
              item.routeNames?.includes($route.name)) &&
              'menu-item-active',
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <span class="menu-text">{{ item.label }}</span>
          </a>
        </li>
      </router-link>
    </fragment>
  </ul>
</template>

<script>
export default {
  name: "KTMenu",
  props: {
    subMenu: Array,
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>
