<template>
  <div id="kt_header" ref="kt_header" class="header header-fixed">
    <div
      class="header-wrapper rounded-top-xl d-flex flex-grow-1 align-items-center"
    >
      <div
        v-if="showSubMenu != null"
        class="container-fluid d-flex align-items-center justify-content-end justify-content-lg-between flex-wrap"
      >
        <div
          class="header-menu-wrapper header-menu-wrapper-left py-lg-2"
          id="kt_header_menu_wrapper"
          ref="kt_header_menu_wrapper"
        >
          <div
            id="kt_header_menu"
            ref="kt_header_menu"
            class="header-menu header-menu-mobile header-menu-layout-default"
          >
            <KTMenu :subMenu="subMenuItem"></KTMenu>
          </div>
        </div>
        <div
          v-if="showSubMenu.chooseStore"
          class="d-flex align-items-center py-3"
        >
          <b-button variant="light-primary" class="font-weight-bolder mr-2">
            Kasir : {{ user.fullName }}
          </b-button>
          <b-button
            variant="light-primary"
            class="font-weight-bolder dropdown-toggle"
            @click="openModalStore"
          >
            Store : {{ store?.storeName ?? "Pilih Store" }}
          </b-button>
        </div>
      </div>
      <div v-else>
        <div
          class="container-fluid d-flex align-items-center justify-content-end justify-content-lg-between flex-wrap"
        >
          <div
            class="header-menu-wrapper header-menu-wrapper-left py-lg-2"
            id="kt_header_menu_wrapper"
            ref="kt_header_menu_wrapper"
          >
            <div
              id="kt_header_menu"
              ref="kt_header_menu"
              class="header-menu header-menu-mobile header-menu-layout-default"
            >
              <h3 class="font-weight-bold">{{ $route.meta.title }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTMenu from "@/view/layout/header/Menu";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
// import KTDropdown from "@/view/layout/header/Dropdown2";

export default {
  name: "Header",
  components: {
    KTMenu,
    // KTDropdown,
  },
  props: {
    showSubMenu: Object,
    subMenuItem: Array,
  },
  data: () => ({
    // subMenu
  }),
  mounted() {
    KTLayoutHeader.init("kt_header", "kt_header_mobile");

    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_menu_wrapper"]
    );

    const headerRef = this.$refs["kt_header"];

    headerRef.querySelectorAll("a[class='menu-link']").forEach((item) => {
      item.addEventListener("click", () => {
        KTLayoutHeaderMenu.getOffcanvas().hide();
      });
    });
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses", "store"]),
    ...mapGetters("auth", ["user"]),

    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    widthFluid() {
      return this.layoutConfig("header.self.width") === "fluid";
    },
  },
  methods: {
    openModalStore() {
      this.$emit("onSelectStore");
    },
  },
};
</script>
