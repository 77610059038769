<template>
  <b-button
    class="btn btn-icon btn-clean btn-lg mb-1"
    variant="outline-secondary"
    v-b-tooltip.hover
    title="Logout"
    @click="handleLogout"
  >
    <span class="svg-icon svg-icon-danger svg-icon-xl">
      <inline-svg src="media/svg/icons/Electric/Shutdown.svg" />
    </span>
  </b-button>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("auth", ["token"]),
  },
  methods: {
    handleLogout() {
      const self = this;

      self.$store
        .dispatch("apis/get", {
          url: "auth/logout",
          params: {
            refreshToken: self.token.refreshToken,
          },
        })
        .then((response) => {
          localStorage.removeItem("vuex");

          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });
          } else {
            self.$message.success({
              zIndex: 10000,
              message: response.message,
            });
          }
          window.location.reload();
        });
    },
  },
};
</script>
